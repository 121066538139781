<template>
  <div class="message-index" :style="{'padding-top': statusBarHeight + 'px'}">
    <van-tabs v-model="activeName" sticky class="message-tabs" @click="onClickTab">
      <van-tab title="通知查看" name="message">
        <MessagePage ref="refMessagePage" :style="{height: `calc(100vh - 96px - ${moreHeight}px)`}"/>
      </van-tab>
      <van-tab title="通知管理" name="management">
        <MessageManagePage ref="refMessageManagePage" :style="{height: `calc(100vh - 95px - ${moreHeight}px)`}"/>
      </van-tab>
    </van-tabs>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import MessagePage from '@/components/message/MessagePage'
import MessageManagePage from '@/components/message/MessageManagePage'
export default {
  name: 'MessageIndex',
  components: {
    MessagePage,
    MessageManagePage
  },
  data () {
    return {
      activeName: 'message'
    }
  },
  computed: {
    ...mapState({
      statusBarHeight: state => state.global.statusBarHeight,
      moreHeight: state => state.global.moreHeight
    })
  },
  mounted () {
    this.getUnreadNum()
  },
  methods: {
    onClickTab (name) {
      if (name === 'message') {
        if (this.$refs['refMessagePage']) {
          this.$refs['refMessagePage'].getNewList()
          this.getUnreadNum()
        }
      } else if (name === 'management') {
        if (this.$refs['refMessageManagePage']) {
          this.$refs['refMessageManagePage'].onTaskLoad()
        }
      }
    },
    getUnreadNum () {
      this.$api.earlyWarning.getUnreadNum().then(res => {
        this.$eventBus.$emit('unreadNum', res.unread)
      })
    }
  }
}
</script>
<style lang="less">
.message-index {
  background-image: url("~@/assets/background.png");
  background-size: 100% 40%;
  background-repeat: no-repeat;
  .message-tabs {
    .van-tabs__wrap {
      height: 40px;
      .van-tabs__nav {
        background: transparent;
        .van-tab {
          font-size: 19px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #FFFFFF;
          opacity: 0.7;
          flex: 0 1 auto;
          margin: 0 15px 0;
        }
        .van-tab--active {
          opacity: 1;
        }
        .van-tabs__line {
          display: none;
        }
      }
    }
  }
}
</style>
