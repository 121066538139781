<template>
  <div class="message-page">
    <van-list
      v-model="messageLoading"
      :finished="messageFinished"
      @load="onMessageLoad"
    >
      <van-swipe-cell v-for="item in messageList" :key="item.id" :before-close="beforeSwipeClose" @open="saveTargetItem(item)">
        <van-cell @click="linkToDetail(item)">
          <template slot="default">
            <div>
              <div class="message-list-title">
                <!-- <van-badge :content="'未读'" v-if="!item.markRead"/> -->
                <van-badge dot v-if="!item.markRead" />
                {{ item.subject }}
                <div class="ai-icon" v-if="item.isAiPush">AI推送</div>
              </div>
              <div class="message-list-date">{{ item.noticeTime | moment("YYYY-MM-DD HH:mm:ss") }}</div>
            </div>
          </template>
        </van-cell>
        <template #right>
          <van-button square text="删除" type="danger" class="delete-button" />
        </template>
      </van-swipe-cell>
    </van-list>
    <div class="read-all" @click="clickReadAll">全部已读</div>
  </div>
</template>
<script>
import TableChart from '@/components/charts/TableChart'
import MessageTable from './MessageTable'
export default {
  name: 'MessagePage',
  components: {
    TableChart,
    MessageTable
  },
  data () {
    return {
      messageLoading: false,
      messageFinished: false,
      messageList: [],
      deleteTargetItem: {},
      totalPage: 0,
      currentPage: 0
    }
  },
  mounted () {
  },
  methods: {
    getNewList () {
      this.messageList.splice(0)
      this.currentPage = 0
      if (this.messageFinished) {
        this.messageFinished = false
      } else {
        this.onMessageLoad()
      }
    },
    onMessageLoad () {
      this.$api.earlyWarning.getNoticeList({ page: this.currentPage, size: 15 }).then(res => {
        this.messageList.push({
          content: {
            dataRegionId: '111153445258383360',
            keywordMatch: [
              {
                'id': '84690757569466368',
                'keywordInput': '上个月',
                'keywordMatchMode': 'REGEX_MATCH',
                'keywordCategory': 'DATETIME',
                'keywordCategoryExtra': 'COMMON',
                'keywordOutputDirect': '上个月',
                'relationKeyword': '年月',
                'locked': false,
                'orderNumber': 3,
                'description': '',
                'matchedValue': '上个月',
                'kindDesc': '年月',
                'used': false,
                'showInResult': true,
                'exclude': false,
                'ignoreForRequest': false,
                'implicit': false,
                'totalMatchKey': '上个月',
                'invalid': false,
                'keywordCategoryDisplayName': '时间条件',
                'keywordMatchModeDisplayName': '正则匹配',
                'datetimeConditionExpression': {
                  'and': [
                    {
                      'fieldName': '年月',
                      'operator': 'eq',
                      'value': '202204',
                      'offsetPosition': 'MONTH',
                      'offsetOperator': '-',
                      'offsetValue': '1'
                    }
                  ]
                },
                'synonym': false,
                'keywordCategoryExtraDisplayName': '通用',
                'dateRange': {
                  'start': '2022-04-01T00:00:00.000+08:00',
                  'end': '2022-04-30T23:59:59.999+08:00',
                  'dateFieldType': 'YEAR_MONTH',
                  'single': true
                }
              },
              {
                'id': '309007482925076480',
                'keywordInput': '销售业务分析',
                'keywordMatchMode': 'COMPLETE_MATCH',
                'keywordCategory': 'REPORT_VIEW',
                'keywordCategoryExtra': 'COMMON',
                'keywordOutputDirect': '销售业务分析',
                'relationEntityId': '308996213409042432',
                'locked': false,
                'orderNumber': 6,
                'description': '',
                'matchedValue': '销售业务分析',
                'originMatchedValue': '销售业务分析',
                'kindDesc': '搜索融合',
                'dataRegionId': '111153445258383360',
                'used': false,
                'showInResult': true,
                'exclude': false,
                'ignoreForRequest': false,
                'implicit': false,
                'totalMatchKey': '销售业务分析',
                'invalid': false,
                'source': '111153445258383360',
                'keywordCategoryDisplayName': '搜索融合',
                'keywordMatchModeDisplayName': '完全匹配',
                'keywordReportViewProperties': {
                  'url': 'in_search',
                  'invokeType': 'window',
                  'params': [
                    {
                      'name': '',
                      'ref': '',
                      'value': '',
                      'multiply': false
                    }
                  ]
                },
                'datetimeConditionExpression': {},
                'synonym': false,
                'keywordCategoryExtraDisplayName': '通用'
              }
            ],
            keywordSearchHistoryId: 'null',
            searchWords: '上个月销售业务分析报告'
          },
          createdBy: 'admin',
          createdDate: '2022-05-03T08:30:00.000+08:00',
          id: 'richReportNotice0',
          lastModifiedBy: 'admin',
          lastModifiedDate: '2022-05-03T08:30:00.000+08:00',
          markRead: true,
          isAiPush: true,
          noticeTime: '2022-05-03T08:30:00.000+08:00',
          subject: '[上个月销售业务分析报告]',
          username: 'admin'
        })
        // this.messageList.push({
        //   content: {
        //     dataRegionId: '111153445258383360',
        //     keywordMatch: [
        //       {
        //         'id': '84690757569466368',
        //         'keywordInput': '上个月',
        //         'keywordMatchMode': 'REGEX_MATCH',
        //         'keywordCategory': 'DATETIME',
        //         'keywordCategoryExtra': 'COMMON',
        //         'keywordOutputDirect': '上个月',
        //         'relationKeyword': '年月',
        //         'locked': false,
        //         'orderNumber': 3,
        //         'description': '',
        //         'matchedValue': '上个月',
        //         'kindDesc': '年月',
        //         'used': false,
        //         'showInResult': true,
        //         'exclude': false,
        //         'ignoreForRequest': false,
        //         'implicit': false,
        //         'totalMatchKey': '上个月',
        //         'invalid': false,
        //         'synonym': false,
        //         'keywordMatchModeDisplayName': '正则匹配',
        //         'keywordCategoryDisplayName': '时间条件',
        //         'dateRange': {
        //           'start': '2022-03-01T00:00:00.000+08:00',
        //           'end': '2022-03-31T23:59:59.999+08:00',
        //           'dateFieldType': 'YEAR_MONTH',
        //           'single': true
        //         },
        //         'keywordCategoryExtraDisplayName': '通用',
        //         'datetimeConditionExpression': {
        //           'and': [
        //             {
        //               'fieldName': '年月',
        //               'operator': 'eq',
        //               'value': '202203',
        //               'offsetPosition': 'MONTH',
        //               'offsetOperator': '-',
        //               'offsetValue': '1'
        //             }
        //           ]
        //         }
        //       },
        //       {
        //         'id': '286612627955634176',
        //         'keywordInput': '生产经营',
        //         'keywordMatchMode': 'COMPLETE_MATCH',
        //         'keywordCategory': 'REPORT_VIEW',
        //         'keywordCategoryExtra': 'COMMON',
        //         'keywordOutputDirect': '生产经营',
        //         'relationEntityId': '286612620481384448',
        //         'locked': false,
        //         'orderNumber': 4,
        //         'description': '',
        //         'matchedValue': '生产经营',
        //         'originMatchedValue': '生产经营',
        //         'kindDesc': '搜索融合',
        //         'dataRegionId': '111153445258383360',
        //         'used': false,
        //         'showInResult': true,
        //         'exclude': false,
        //         'ignoreForRequest': false,
        //         'implicit': false,
        //         'totalMatchKey': '生产经营',
        //         'invalid': false,
        //         'source': '111153445258383360',
        //         'synonym': false,
        //         'keywordMatchModeDisplayName': '完全匹配',
        //         'keywordReportViewProperties': {
        //           'url': 'in_search',
        //           'invokeType': 'window',
        //           'params': [
        //             {
        //               'name': '',
        //               'ref': '',
        //               'value': '',
        //               'multiply': false
        //             }
        //           ]
        //         },
        //         'keywordCategoryDisplayName': '搜索融合',
        //         'keywordCategoryExtraDisplayName': '通用',
        //         'datetimeConditionExpression': {}
        //       }
        //     ],
        //     keywordSearchHistoryId: 'null',
        //     searchWords: '上个月生产经营情况分析报告'
        //   },
        //   createdBy: 'admin',
        //   createdDate: '2022-03-03T07:26:00.000+08:00',
        //   id: 'richReportNotice1',
        //   lastModifiedBy: 'admin',
        //   lastModifiedDate: '2022-03-03T07:26:00.000+08:00',
        //   markRead: true,
        //   isAiPush: true,
        //   noticeTime: '2022-03-03T07:26:00.000+08:00',
        //   subject: '上个月生产经营情况分析报告',
        //   username: 'admin'
        // })
        this.messageList.push(...res.content)
        this.totalPage = res.totalPages
        this.currentPage = res.number + 1
        this.messageLoading = false
        if (this.currentPage >= this.totalPage) {
          this.messageFinished = true
        }
      }).catch(() => {
        this.messageLoading = false
        this.messageFinished = true
      })
    },
    showDetailMess (dataRequest) {
      // 清空之前的搜索历史
      this.$store.dispatch('speech/removeSearchHistory', dataRequest.dataRegionId).then(() => {
        this.$router.push({ name: 'Speech', params: { dataRequest: dataRequest } })
      })
    },
    saveTargetItem(item) {
      this.deleteTargetItem = item
    },
    getUnreadNum () {
      this.$api.earlyWarning.getUnreadNum().then(res => {
        this.$eventBus.$emit('unreadNum', res.unread)
      })
    },
    beforeSwipeClose ({ position, instance }) {
      switch (position) {
        case 'right':
          window.history.pushState({ title: '通知详情删除提示', url: '#' }, '通知详情删除提示', '#')
          this.$dialog.confirm({
            message: '确定删除吗？'
          }).then(() => {
            this.$api.earlyWarning.deleteNotice(this.deleteTargetItem.id).then(res => {
              this.$toast.success('删除成功')
              this.getNewList()
              this.getUnreadNum()
            }).catch(() => {
              this.$toast.fail('删除失败')
              this.getNewList()
            })

            instance.close()
          }).catch(() => {
            instance.close()
          })
          break
        default:
          instance.close()
      }
    },
    linkToDetail (item) {
      let detailUrl = null
      let dataContent = null
      if (typeof (item.content) === 'string') {
        detailUrl = item.content.match(/\((.+?)\)/g)[0]
      } else {
        dataContent = item.content
      }
      this.$router.push({
        name: 'MessageDetail',
        params: {
          detailUrl: detailUrl,
          dataContent: dataContent,
          noticeId: item.markRead ? '' : item.id
        }
      })
    },
    clickReadAll () {
      this.$api.earlyWarning.markNoticeReadAll().then(res => {
        this.$toast.success('全部已读')
        this.getNewList()
        this.$eventBus.$emit('unreadNum', 0)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.message-page {
  overflow: auto;
  padding-top: 6px;
  .van-cell {
    margin: 0 15px 15px;
    width: calc(100% - 30px);
    border-radius: 10px;
    &::after {
      border-bottom: none;
    }
  }
  .message-list-title {
    font-size: 15px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    .ai-icon {
      display: inline-block;
      border: 1px solid #f5b0b0;
      // background-color: #ffdbdb;
      padding: 0 6px;
      text-align: center;
      vertical-align: middle;
      font-style: normal;
      color: red;
      overflow: hidden;
      line-height: 16px;
      height: 15px;
      font-size: 12px;
      border-radius: 8px;
      font-weight: 300;
    }
  }
  .message-list-date {
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #878787;
    line-height: 28px;
  }
  .delete-button {
    height: 100%;
  }
  .read-all {
    display: inline-block;
    position: absolute;
    top: 10px;
    left: 243px;
    color: #ffffff;
  }
}
</style>
