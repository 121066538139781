<template>
  <div class="message-management" :style="{height: `calc(100vh - 90px - ${statusBarHeight}px - ${iosBottomHeight}px)`}">
    <van-list
      v-model="messageLoading"
      :finished="messageFinished"
      @load="onTaskLoad"
    >
      <van-cell v-for="item in taskList" :key="item.id" :class="item.taskStatus === 'ACTIVE' ? 'active-message' : 'inactive-message'" :style="{'background-image': 'url(' + item.taskStatus === 'INACTIVE'? inActiveBackground: activeBackground + ')'}">
        <template slot="title">
          <div class="message-management-title">
            <img v-if="item.taskStatus === 'ACTIVE'" src="@/assets/img/icon/alarm-clock.png" />
            {{ item.taskName }}
          </div>
        </template>
        <!-- <template slot="default">
          <div>({{ item.taskId }})</div>
        </template> -->
        <!-- <template slot="extra">
          <div>
            <span style="padding-right: 5px; color: #1890ff" @click="editTask(item)">编辑</span>
            <span style="color: #1890ff" @click="deleteTask(item)">删除</span>
          </div>
        </template> -->
        <template slot="label">
          <div class="message-management-content">
            <div>执行计划：{{ formatCron(item.cron) }}</div>
            <div v-if="item.lastRunTime">最后执行：{{ item.lastRunTime | moment("YYYY-MM-DD HH:mm:ss") }}</div>
            <div v-else>最后执行：暂未执行</div>
            <div class="message-management-action">
              <div @click="editTask(item)">
                <van-icon name="edit" />编辑
              </div>
              <div @click="deleteTask(item)">
                <van-icon name="delete-o" />删除
              </div>
            </div>
            <!-- <div>任务状态：{{ item.taskStatus === 'INACTIVE'?'已禁用': '已启动' }}</div> -->
          </div>
        </template>
        <div class="task-status">{{ item.taskStatus === 'INACTIVE'?'已禁用': '已启动' }}</div>
      </van-cell>
    </van-list>
    <EarlyWarningDialog ref="refEarlyWarningDialog" :metricsArray="reportChartData['metrics']" @editNotice="onTaskLoad"/>
  </div>
</template>
<script>
import EarlyWarningDialog from '@/components/speech/EarlyWarningDialog'
import ReportChartData from '@/utils/reportData'

export default {
  name: 'MessageManagePage',
  components: {
    EarlyWarningDialog
  },
  data () {
    return {
      messageLoading: false,
      messageFinished: false,
      taskList: [],
      reportChartData: [],
      weekColumns: [
        { text: '周一', id: 0, word: 'MON' },
        { text: '周二', id: 1, word: 'Tue' },
        { text: '周三', id: 2, word: 'Wed' },
        { text: '周四', id: 3, word: 'Thu' },
        { text: '周五', id: 4, word: 'Fri' },
        { text: '周六', id: 5, word: 'Sat' },
        { text: '周日', id: 6, word: 'Sun' }
      ],
      inActiveBackground: null,
      activeBackground: null
    }
  },
  methods: {
    onTaskLoad () {
      this.$api.earlyWarning.getTaskList().then(res => {
        this.taskList = res
        this.messageLoading = false
        this.messageFinished = true
      }).catch(() => {
        this.messageLoading = false
        this.messageFinished = true
      })
    },
    editTask (item) {
      this.$refs['refEarlyWarningDialog'].create(item)
      this.getReportData(item)
    },
    deleteTask (item) {
      window.history.pushState({ title: '通知管理删除提示', url: '#' }, '通知管理删除提示', '#')
      this.$dialog.confirm({
        message: '确定要删除通知 ' + item.taskName + ' 吗？'
      }).then(() => {
        console.log(item.id)
        this.$api.earlyWarning.deleteTask(item.id).then(res => {
          this.$toast.success('删除成功')
          this.onTaskLoad()
        }).catch(() => {
          this.$toast.fail('')
        })
      }).catch(() => {
        // on cancel
      })
    },
    getReportData (item) {
      this.$api.speech.getReportData(item.dataRequest).then(res => {
        this.reportChartData = new ReportChartData(res[0])
      }).catch(() => {
        this.$toast.fail('')
      })
    },
    formatCron (corn) {
      console.log('corn', corn)
      const cronArray = corn.split(' ')
      console.log('cronArray', cronArray)
      if (cronArray[1].length === 1) cronArray[1] = '0' + cronArray[1]
      if (cronArray[2].length === 1) cronArray[2] = cronArray[2] === '*' ? '*' : '0' + cronArray[2]
      let performStr = ''
      if (cronArray[2] === '*') {
        performStr = '每小时 ' + cronArray[1] + '分'
      } else if (cronArray[3] === '*') {
        performStr = `每天 ${cronArray[2]}: ${cronArray[1]}`
      } else if (cronArray[3] === '?') {
        const weeksTypeArray = []
        cronArray[5].split(',').forEach(item => {
          const weekItem = this.weekColumns.find(week => week.word === item)
          weeksTypeArray.push(weekItem.text)
        })
        performStr = `每周 (${weeksTypeArray.join('、')}) ${cronArray[2]}: ${cronArray[1]}`
      } else if (cronArray[4] === '*') {
        const monthDateArray = []
        cronArray[3].split(',').forEach(item => {
          monthDateArray.push(item + '号')
        })
        performStr = `每月 (${monthDateArray.join('、')}) ${cronArray[2]}: ${cronArray[1]}`
      } else if (cronArray[6] === '') {
        const quarterDateArray = []
        cronArray[3].split(',').forEach(item => {
          quarterDateArray.push(item + '号')
        })
        performStr = `每季度 第${cronArray[4].split('/')[0]}个月(${quarterDateArray.join('、')}) ${cronArray[2]}: ${cronArray[1]}`
      } else {
        performStr = `不重复 ${cronArray[2]}: ${cronArray[1]}`
      }
      return performStr
    },
    popstateListener(e) {
      const refEarlyWarningDialog = this.$refs['refEarlyWarningDialog']
      if (history.state.title === '预警编辑' && refEarlyWarningDialog.visible) { // 通知预警弹窗
        const refConditionSetting = refEarlyWarningDialog.$refs['refConditionSetting'].$refs['refConditionSettingpopup']
        if (refConditionSetting.showCreateCondition) { // 条件设置弹窗
          refConditionSetting.showCreateCondition = false
        }
      } else if (!history.state.title) {
        refEarlyWarningDialog.visible = false
      }
    }
  },
  mounted () {
    this.inActiveBackground = require('@/assets/img/icon/inactive.png')
    this.activeBackground = require('@/assets/img/icon/active.png')
    window.addEventListener('popstate', this.popstateListener, false)
  },
  destroyed() {
    window.removeEventListener('popstate', this.popstateListener)
  }
}
</script>
<style lang="less" scoped>
.message-management {
  overflow: auto;
  padding-top: 6px;
  .van-cell {
    margin: 0 15px 15px;
    width: calc(100% - 30px);
    border-radius: 10px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-color: transparent;
    position: relative;
    padding: 0;
    &::after {
      border-bottom: none;
    }
    .van-cell__title {
      width: 75%;
      flex: 0 1 auto;
      padding: 10px 0 10px 16px;
      .message-management-title {
        font-size: 15px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
        img {
          width: 18px;
          height: 18px;
          vertical-align: sub;
        }
      }
      .message-management-content {
        font-size: 13px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #666666;
        line-height: 18px;
        margin-top: 8px;
        .message-management-action {
          display: flex;
          width: calc(100% / 0.75 - 32px);
          margin: 15px 0 5px;
          div {
            display: flex;
            flex: 1;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: bold;
            line-height: 18px;
            padding: 8px 0;
          }
          i {
            font-size: 14px;
            font-weight: bold;
            line-height: 18px;
          }
          div:first-child {
            margin-right: 15px;
            background: linear-gradient(96deg, #2680E7, #3CB9DD);
            border-radius: 17px;
            color: #FFFFFF;
          }
          div:last-child {
            background: #F5F7FA;
            border-radius: 17px;
            margin-left: 15px;
            color: #319DE2;
          }
        }
      }
    }
    .van-cell__value {
      pointer-events: none;
      .task-status {
        position: absolute;
        right: 8px;
        top: 8px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
        line-height: 13px;
      }
    }
  }
  .active-message {
    background-image: url(~@/assets/img/icon/active.png)
  }
  .inactive-message {
    background-image: url(~@/assets/img/icon/inactive.png)
  }
}
</style>
